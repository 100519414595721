<template>
	<div class="row m-0">
      <div class="col-md-6 px-1">
         <div class="card mb-2">
            <div class="card-body">
               <h4 class="font-20 mb-3">Discord Monkey</h4>
               <img class="cursor-pointer" src="@/assets/admin/images/deck-appa-page-banner.png" alt="Start image"  width="100%" @click="irDiscord('https://discord.gg/monkey-fivem-dev-793484634725548053')">
            </div>
         </div>
      </div>
      <div class="col-md-6 px-1">
         <div class="card mb-2">
            <div class="card-body">
               <h4 class="font-20 mb-3">Discord Curso</h4>
               <img class="cursor-pointer" src="@/assets/admin/images/deck-appa-page-banner.png" alt="Start image"  width="100%" @click="irDiscord('https://discord.gg/3WzW5KCegG')">
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'Discord',
	computed: {
		...mapState({
			idUsuario: state => state.idUsuario,
			dadosUsuario:  state => state.dadosUsuario,
         urlRest: state => state.urlRest
		})
	},
   methods: {
      irDiscord : function (link) {
			window.open(link, '_blank');
		}
   }
}

</script>